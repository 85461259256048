<template>
  <v-container>
    <v-col>
      InviteID: {{ params.addr }}<br>
      x: {{ x }}
      <v-card elevation="8" class="">
        <router-link :to="`/invite/` + params.gameId">{{ params.gameId }}</router-link>
        <v-expansion-panels class="mb-6">
          <v-expansion-panel>
            <v-expansion-panel-header expand-icon="mdi-menu-down">
              bets
            </v-expansion-panel-header>
            <v-expansion-panel-content v-if="!params.bets">no bets yet</v-expansion-panel-content>
            <v-expansion-panel-content v-for="(bet,i) in params.bets" v-else :key="i">{{ bet.player }} {{ bet.total }}</v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
      <v-btn @click="doSim">
        SIM RESULT
      </v-btn>
    </v-col>
  </v-container>
</template>

<script>
  import {
    mapGetters,
    mapState,
  } from 'vuex'
  export default {
    name: 'GameResults',
    components: {
    },
    data () {
      return {
        x: {},
        sim: null,
      }
    },
    computed: {
      ...mapState('route', ['params']),
      ...mapState('platform', ['mainHost']),
      ...mapState('wallet', ['address']),
      ...mapGetters('node', ['node']),
    },
    async mounted () {
      var ret = (await this.$http.get(this.mainHost + '/invite?id=' + this.params.addr)).data
      this.x = ret
    },
    methods: {
      async doSim () {
        var ret = (await this.$http.get(this.mainHost + '/invite?sim=true&id=' + this.params.addr)).data
        this.sim = ret
        console.log('do sim', ret)
      },
      gotoHash (hash) {
        this.$router.push('/result/' + hash)
      },
    },
  }
</script>
